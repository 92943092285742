import { isLengthyArray } from '~helpers';

export default {
    findHelpByNavigation(navNames) {
        var helpObj = {
            clips: [],
            tours: [],
            training: []
        }

        if (navNames == null) {
            return helpObj;
        }

        var nList = navNames;
        if (!Array.isArray(navNames)) {
            nList = [navNames];
        }

        var tourList = this.getTours();

        helpObj.clips = this.clips.filter(z => isLengthyArray(z.navigations) && z.navigations.some(nav => nList.some(n => nav == n)));
        helpObj.tours = tourList.filter(z => isLengthyArray(z.navigations) && z.navigations.some(nav => nList.some(n => nav == n)));
        helpObj.training = this.training.filter(t => isLengthyArray(t.navigations) && t.navigations.some(nav => nList.some(n => nav == n)));

        return helpObj;
        //return this.data.find(x => x.navigation == navName);
    },
    clips: [ //{ title: 'How To', clipID: '', navigations: [] }
        {
            clipID: 'X8pIKczKu50',
            navigations: [
                'driver-hub',
                'driver-training'],
            title: 'Training: Deliveries'
        },
        {
            clipID: 'dTvBXZlO0CE',
            navigations: [
                'picking-hub',
                'picker-training'],
            title: 'Training: Picking'
        },
        {
            clipID: 'OGhlPYPfV2Y',
            navigations: [
                'dispatch-hub',
                'dispatch-release',
                'dispatch-journey',
                'dispatch-training',
                'dispatch-journey-training',
                'dispatch-release-training',
                'customer-consignment-training',
                'journey-optimization-training'],
            title: 'Training: Dispatch'
        },
        {
            clipID: 'JPjp7JnCy08',
            navigations: [
                'journey-optimization-hub',
                'journey-optimization-training'],
            title: 'Training: Journey Optimization'
        },
        {
            clipID: '2nUXmNtYQtU',
            navigations: [
                'template-optimization-hub',
                'template-optimization-training'
            ],
            title: 'Training: Template Optimization'
        },
        {
            clipID: 'ETM-lL_pkEU',
            navigations: [
                'packing-hub',
                'packer-training'],
            title: 'Training: Packing'
        },
        {
            clipID: 'lf_4rcZizPY',
            navigations: [
                'production-hub',
                'production-training'],
            title: 'Training: Production Tool'
        },
        {
            clipID: 'm4S0lXF_MBs',
            navigations: [
                'stock-balancer-hub',
                'stock-balancer-training'],
            title: 'Training: Stock Balancing Tool'
        },
        {
            clipID: 'oTl3Z1Fd4lU',
            navigations: [
                'sales-hub',
                'sales-training'],
            title: 'Training: Sales Tool'
        }
    ],
    training: [ //{ title: 'Train', trainingNavigation: '', navigations: [] }
        {
            navigations: ['production-training', 'production-hub'],
            trainingNavigation: 'production-training'
        },
        { 
            navigations: ['dispatch-training', 'dispatch-hub'],
            trainingNavigation: 'dispatch-training'
        },
        {
            navigations: ['driver-training', 'driver-hub'],
            trainingNavigation: 'driver-training'
        },
        {
            navigations: ['journey-optimization-training', 'journey-optimiziation-hub'],
            trainingNavigation: 'journey-optimization-training'
        },
        {
            navigations: ['packer-training', 'packing-hub'],
            trainingNavigation: 'packer-training'
        },
        {
            navigations: ['picker-training', 'picking-hub'],
            trainingNavigation: 'picker-training'
        },
        {
            navigations: ['sales-training', 'sales-hub'],
            trainingNavigation: 'sales-training'
        },
        {
            navigations: ['stock-checker-training', 'stock-checker-hub'],
            trainingNavigation: 'stock-checker-training'
        },
        {
            navigations: ['stock-balancer-training', 'stock-balancer-hub'],
            trainingNavigation: 'stock-balancer-training'
        }
    ],
    getTours() {
        if (this.tours[0].id == undefined) {
            var ind = 1;
            this.tours.forEach(t => {
                t.id = ind.toString();
                ind += 1;
            })
        }

        return this.tours;
    },
    tours: [ 
        // {
        //     id: 'bladeName',
        //     navigations: [],
        //     title: '',
        //     steps: [
        //         {
        //             content: '',
        //             only: [],  if any of the steps is only, then do not include steps without only
        //             status: [], also include steps with status and only undefined
        //             target: ''
        //         }
        //     ]
        // }
        {
            navigations: ['all'],
            title: 'Tour The User Interface',
            //app-sidebar, app-bar
            steps: [
                {
                    content: 'Access tools in the hub menu',
                    status: ['', 'app-bar'],
                    target: '.v-step-hub-menu'
                },
                {
                    content: 'Navigate to data through the sidebar',
                    status: ['app-sidebar'],
                    target: '.v-step-navigation-bar'
                },
                { 
                    content: 'Toggle through different perspectives to access different sets of navigation items in the sidebar',
                    status: ['app-sidebar'],
                    target: '.v-step-subscription-toggle'
                },
                { 
                    content: 'Log out with ease',
                    status: ['app-sidebar'],
                    target: '.v-step-logout'
                },
                {
                    content: 'Sometimes it speeds things up to say where you are working from',
                    status: ['app-sidebar'],
                    target: '.v-step-location-selector'
                },
                {
                    content: 'Get help for whatever page or tool you are using',
                    status: ['', 'app-bar'],
                    target: '.v-step-help'
                }
            ]
        },
        {
            navigations: ['batch'],
            steps: [
                {
                    content: 'Create a brand new batch',
                    only: ['options'],
                    target: '.v-step-batch-new'
                },
                {
                    content: 'Convert an existing batch into a new batch (ie. package a wheel of cheese into fixed weights)',
                    only: ['options'],
                    target: '.v-step-batch-convert'
                },
                {
                    content: 'Select where the batch is being produced',
                    status: ['new', 'convert'],
                    target: '.v-step-batch-location'
                },
                {
                    content: 'Select what batch you are converting into a new batch',
                    status: ['convert'],
                    target: '.v-step-batch-batch'
                },
                {
                    content: 'How many units of this batch are being used to create the new batch',
                    status: ['convert'],
                    target: '.v-step-batch-used'
                },
                {
                    content: 'Select what product is being produced',
                    target: '.v-step-batch-product'
                },
                {
                    content: 'Pending means the batch is due to be produced but not yet',
                    target: '.v-step-batch-pending'
                },
                {
                    content: 'On Hold means the finished batch will not be available for sale until later released from being on hold',
                    target: '.v-step-batch-onhold'
                },
                {
                    content: 'The goal of how many units to make',
                    target: '.v-step-batch-goal'
                },
                {
                    content: 'How many units were actually made',
                    target: '.v-step-batch-made'
                }
            ]
        },
        {
            navigations: ['batches'],
            steps: [
                {
                    content: 'Delete batches',
                    target: 'v-step-item-actions'
                }
            ]
        },
        {
            navigations: ['customer-order'],
            steps: [
                {
                    content: 'View purchase orders <br>Open: Shift + 1<br>New: Alt + 1',
                    target: '.v-step-sales-orders'
                }
            ]
        },
        {
            navigations: ['customer-orders'],
            steps: [
                // {
                //     content: 'The customer order number / stock consignment number',
                //     target: '.v-step-CO#'
                // },
                {
                    content: 'When the customer order is due to be delivered',
                    target: '.v-step-duedate'
                },
                {
                    content: 'The name of the customer who is purchasing the order',
                    target: '.v-step-customer'
                },
                {
                    content: 'The destination of the customer order',
                    target: '.v-step-destination'
                }
            ]
        },
        {
            navigations: ['sales-hub'],
            title: 'Tour The Sales Hub',
            steps: [
                {
                    content: 'View purchase orders <br>Open: Shift + 1<br>New: Alt + 1',
                    target: '.v-step-sales-orders'
                },
                {
                    content: 'View standing orders <br>Open: Shift + 2<br>New: Alt + 2',
                    target: '.v-step-sales-standing-orders'
                },
                { 
                    content: 'View customer invoices <br>Open: Shift + 3',
                    target: '.v-step-sales-invoices'
                },
                { 
                    content: 'View stock consignments <br>Open: Shift + 4',
                    target: '.v-step-sales-consignments'
                },
                {
                    content: 'View deliveries/movements <br>Open: Shift + 5',
                    target: '.v-step-sales-movements'
                },
                {
                    content: 'Select a customer and filter orders accordingly <br>Change: ~',
                    target: '.v-step-sales-customer'
                }
            ]
        },
    ],
}